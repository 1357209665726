"use client";

import { useContext } from "react";
import { Translation } from "../Translation/Translation.component";
import { PaginationContext } from "./pagination.context";
export function PaginationPageDisplay() {
  const {
    currentPage,
    totalPages
  } = useContext(PaginationContext);
  return <p className="py-1 text-sm text-nobel" data-sentry-component="PaginationPageDisplay" data-sentry-source-file="PaginationPageDisplay.component.tsx">
      <Translation da={`Side ${currentPage}`} de={`Seite ${currentPage}`} en={`Page ${currentPage}`} no={`Side ${currentPage}`} sv={`Sida ${currentPage}`} data-sentry-element="Translation" data-sentry-source-file="PaginationPageDisplay.component.tsx" />

      {totalPages !== null ? <Translation da={` af ${totalPages}`} de={` von ${totalPages}`} en={` of ${totalPages}`} no={` av ${totalPages}`} sv={` av ${totalPages}`} /> : null}
    </p>;
}