"use client";

import type { Dispatch, ReactNode, SetStateAction } from "react";
import React, { createContext, useMemo, useState } from "react";
type PaginationContextProps = {
  readonly currentPage: number;
  readonly hasNextPage: boolean;
  readonly hasPreviousPage: boolean;
  readonly isDisabled: boolean;
  readonly setCurrentPage: Dispatch<SetStateAction<number>>;
  readonly totalPages: number | null;
};
type PaginationProviderProps = {
  readonly children: ReactNode;
  readonly totalPages: number | null;
  readonly isDisabled: boolean;
  readonly page: number;
};
export const PaginationContext = createContext<PaginationContextProps>({
  currentPage: 1,
  hasNextPage: false,
  hasPreviousPage: false,
  isDisabled: false,
  setCurrentPage: () => {
    /* Silenzio Bruno */
  },
  totalPages: null
});
export function PaginationProvider({
  children,
  isDisabled,
  page,
  totalPages
}: PaginationProviderProps) {
  const [currentPage, setCurrentPage] = useState(page);
  const hasNextPage = totalPages !== null ? currentPage < totalPages : true;
  const hasPreviousPage = currentPage > 1;
  const value = useMemo(() => ({
    currentPage,
    hasNextPage,
    hasPreviousPage,
    isDisabled,
    setCurrentPage,
    totalPages
  }), [currentPage, hasNextPage, hasPreviousPage, isDisabled, totalPages]);
  return <PaginationContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PaginationProvider" data-sentry-source-file="pagination.context.tsx">
      {children}
    </PaginationContext.Provider>;
}