"use client";

import clsx from "clsx";
import { useContext } from "react";
import { OutlineChevronLeftIcon } from "../Icons/components/HeroIcons/OutlineChevronLeftIcon.component";
import { OutlineChevronRightIcon } from "../Icons/components/HeroIcons/OutlineChevronRightIcon.component";
import { Link } from "../Link/Link.component";
import { PaginationContext } from "./pagination.context";
type PaginationLinksProps = {
  readonly url: string;
  readonly parameterName?: string;
};
export function PaginationLinks({
  url,
  parameterName = "page"
}: PaginationLinksProps) {
  const {
    currentPage,
    isDisabled,
    hasNextPage,
    hasPreviousPage
  } = useContext(PaginationContext);
  const previousPage = Math.max(currentPage - 1, 1);
  const nextPage = Math.min(currentPage + 1);
  return <div className="flex gap-1" data-sentry-component="PaginationLinks" data-sentry-source-file="PaginationLinks.component.tsx">
      <Link aria-disabled={hasPreviousPage} className={clsx("base-button base-hover-button rounded-none rounded-l-sm bg-primary text-white hover:bg-primary-hover", (!hasPreviousPage || isDisabled) && "cursor-not-allowed bg-silver")} href={`${url}?${parameterName}=${previousPage}`} data-sentry-element="Link" data-sentry-source-file="PaginationLinks.component.tsx">
        <OutlineChevronLeftIcon className="h-2 w-2" data-sentry-element="OutlineChevronLeftIcon" data-sentry-source-file="PaginationLinks.component.tsx" />
      </Link>

      <Link aria-disabled={hasNextPage} className={clsx("base-button base-hover-button rounded-r-sm bg-primary text-white hover:bg-primary-hover", (!hasNextPage || isDisabled) && "cursor-not-allowed bg-silver hover:bg-silver")} href={`${url}?${parameterName}=${nextPage}`} data-sentry-element="Link" data-sentry-source-file="PaginationLinks.component.tsx">
        <OutlineChevronRightIcon className="h-2 w-2" data-sentry-element="OutlineChevronRightIcon" data-sentry-source-file="PaginationLinks.component.tsx" />
      </Link>
    </div>;
}